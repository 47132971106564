import { useLazyQuery, useQuery } from '@apollo/client';
import { AliPlayer, ECryptoType } from '../../components/AliPlayer';
import {
  EResourceType,
  ERoleType,
  GetUserAccessControlDocument,
  GetVideoCoursePageDataDocument,
  GetVideoLessonPlayInfoDocument,
} from '../../services/graphql/types/graphql';
import { useQueryState } from '../../hooks/useQueryState';
import { useContext, useEffect, useRef, useState } from 'react';
import { IdentityContext } from '../App/identity.context';
import { useStoreContext } from '../../factorys/useStoreContext';
import { isNull } from 'lodash';
import { Loader } from '../../components/Loader';
import MuYiTongXueLogo from '../../assets/muyitongxue-logo--new-black.png';
import { AuthingContext } from '../App/authing.context';
import cx from 'classnames';

import { isMobileOnly, isTablet, isIOS } from 'react-device-detect';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

export const VideoCoursePage = () => {
  const { user, role, logout } = useContext(AuthingContext);

  const [courseId] = useQueryState('courseId', '');
  if (!courseId) {
    throw new Error('courseId is required');
  }

  const refScrollContainer = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    if (isMobileOnly && refScrollContainer.current) {
      console.info('lock body scroll');
      disableBodyScroll(refScrollContainer.current);
    }

    return () => {
      clearAllBodyScrollLocks();
    };
  }, []);

  const { uac } = useStoreContext(IdentityContext);
  const [permission, setPermission] = useState<boolean | null>(null);
  useEffect(() => {
    console.info(uac);
    setPermission(
      uac.some(
        (item) =>
          item.resourceType === EResourceType.VideoCourse &&
          item.resourceId === courseId &&
          item.role === ERoleType.Student,
      ),
    );
  }, [uac]);

  const {
    data: courseData,
    loading: courseLoading,
    error: courseFetchError,
  } = useQuery(GetVideoCoursePageDataDocument, {
    variables: { courseId },
  });

  const [currentLessonId, setCurrentLessonId] = useState<string | null>(null);
  const {
    data: videoPlayInfoData,
    loading: videoPlayInfoLoading,
    error: videoPlayInfoError,
  } = useQuery(GetVideoLessonPlayInfoDocument, {
    variables: { lessonId: currentLessonId! },
    skip: !currentLessonId,
  });

  if (isNull(permission)) {
    return <Loader fullscreen></Loader>;
  }

  if (permission === false) {
    return (
      <div className="w-screen h-screen flex items-center justify-center">
        您还未购买课程，如果已经购买请联系客服
      </div>
    );
  }

  if (isMobileOnly) {
    return (
      <div className="w-screen h-screen flex flex-col p-2 gap-2 bg-[#ece9e2] overflow-hidden">
        <div className="bg-black rounded-xl overflow-hidden w-full aspect-w-16 aspect-h-9">
          {videoPlayInfoData && (
            <AliPlayer
              videoId={videoPlayInfoData?.videoLessonPlayInfo.videoId}
              playAuth={videoPlayInfoData?.videoLessonPlayInfo.playAuth}
              cryptoType={isIOS ? ECryptoType.None : ECryptoType.Aliyun}
              // url="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4"
            ></AliPlayer>
          )}
        </div>
        <div className="w-full flex-1 flex flex-col gap-2 overflow-hidden">
          <div className="flex flex-col bg-white px-2 py-2 rounded-xl">
            <div className="flex items-center">
              <img className="rounded-full w-12 h-12" src={user?.photo ?? ''} alt="" />
              <div className="flex flex-col ml-[9px] font-semibold my-auto">
                <div className="text-[20px]">{user?.nickname ?? user?.name}</div>
              </div>
              <div className="w-12 h-12 ml-auto">
                <img className="object-contain" src={MuYiTongXueLogo} alt="" />
              </div>
            </div>
          </div>
          <div className="bg-white rounded-xl h-28 text-[16px] overflow-hidden">
            <img
              className="w-full h-full object-cover"
              src={courseData?.course.bannerImageUrl ?? ''}
              alt=""
            />
          </div>
          <div ref={refScrollContainer} className="rounded-xl flex-1 bg-[#DFDBD1] overflow-auto">
            {courseData?.course.lessons.map((lesson) => {
              return (
                <div
                  className="flex items-center w-full border-b border-white px-2 gap-x-3 flex-shrink-0 h-11 text-[14px]"
                  key={lesson.lessonId}
                  onClick={() => setCurrentLessonId(lesson.lessonId)}
                >
                  <div className="w-[10px] mx-2 flex-shrink-0 flex justify-center items-center">
                    <div
                      className={cx('rounded-full w-[5px] h-[5px] bg-white ', {
                        '!bg-[#F3382F] !w-[10px] !h-[10px]': currentLessonId === lesson.lessonId,
                      })}
                    ></div>
                  </div>
                  {lesson.priority}. {lesson.name}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="w-screen h-screen flex p-4 gap-4 bg-[#ece9e2]">
      <div className="max-w-[350px] w-1/4 h-full flex flex-col gap-4">
        <div className="flex flex-col bg-white px-3 py-6 rounded-xl">
          <div className="w-40 h-40 mx-auto">
            <img className="object-contain" src={MuYiTongXueLogo} alt="" />
          </div>
          <div className="flex items-center mt-5">
            <img className="rounded-full w-12 h-12" src={user?.photo ?? ''} alt="" />
            <div className="flex flex-col ml-[9px] font-semibold my-auto">
              <div className="text-[20px]">{user?.nickname ?? user?.name}</div>
            </div>
          </div>
        </div>
        <div className="bg-white rounded-xl h-28 text-[16px] overflow-hidden">
          <img
            className="w-full h-full object-cover"
            src={courseData?.course.bannerImageUrl ?? ''}
            alt=""
          />
        </div>
        <div className="rounded-xl flex-1 overflow-auto bg-[#DFDBD1]">
          {courseData?.course.lessons.map((lesson) => {
            return (
              <div
                className="flex items-center w-full border-b border-white px-2 gap-x-3 flex-shrink-0 h-11 text-[14px]"
                key={lesson.lessonId}
                onClick={() => setCurrentLessonId(lesson.lessonId)}
              >
                <div className="w-[10px] mx-2 flex-shrink-0 flex justify-center items-center">
                  <div
                    className={cx('rounded-full w-[5px] h-[5px] bg-white ', {
                      '!bg-[#F3382F] !w-[10px] !h-[10px]': currentLessonId === lesson.lessonId,
                    })}
                  ></div>
                </div>
                {lesson.priority}. {lesson.name}
              </div>
            );
          })}
        </div>
      </div>
      <div className="flex-1 bg-white rounded-xl overflow-hidden">
        {videoPlayInfoData && (
          <AliPlayer
            videoId={videoPlayInfoData?.videoLessonPlayInfo.videoId}
            playAuth={videoPlayInfoData?.videoLessonPlayInfo.playAuth}
          ></AliPlayer>
        )}
      </div>
    </div>
  );
};
