import { useEffect } from 'react';
// @ts-expect-error
import Aliplayer from 'aliyun-aliplayer';
import 'aliyun-aliplayer/build/skins/default/aliplayer-min.css';

export enum ECryptoType {
  None = 'None',
  HLS = 'HLS',
  Aliyun = 'Aliyun',
}
export interface AliPlayerProps {
  videoId?: string;
  playAuth?: string;
  url?: string;
  cryptoType?: ECryptoType;
}

export const AliPlayer: React.FC<AliPlayerProps> = ({ videoId, playAuth, url, cryptoType = ECryptoType.Aliyun }) => {
  useEffect(() => {
    const config: any = {
      id: 'J_prismPlayer',
      // autoSize: 'height',
      height: '100%',
      autoplay: false,
      // encryptType: 1,
      license: {
        domain: 'muyi.xinyixue.art', // 申请 License 时填写的域名
        key: '7PnmvyKHlJNT4NmVx25ab1efbac8749bd8fd463eb450bfad6', // 申请成功后，在控制台可以看到 License Key
      },
    };

    if (cryptoType === ECryptoType.HLS) {
      config.playConfig.EncryptType = 'HLSEncryption';
    }

    if (cryptoType === ECryptoType.Aliyun) {
      config.encryptType = 1;
    }

    if (cryptoType === ECryptoType.None) {
      config.format = 'mp4';
    }

    if (url) {
      config.source = url;
    }

    if (videoId && playAuth) {
      config.vid = videoId;
      config.playauth = playAuth;
    }

    var player = new Aliplayer(config);
    player.on('error', (error: any) => {
      console.error(error);
    });

    return () => {
      player.dispose();
    };
  }, [playAuth]);

  return <div id="J_prismPlayer"></div>;
};
