import React, { useContext } from 'react';
import { AuthingContext } from '../App/authing.context';

const ClassroomPage = () => {
  const { user, role } = useContext(AuthingContext);

  if (!user) {
    return <></>;
  }

  return (
    <div className="classroom-page__layout bg-[#231815] w-full h-[100vh] pl-[40px] pb-[70px] pr-[80px]">
      <header className="h-[100px] w-full flex px-[35px] items-center">
        <div className="h-[40px]">
          <img
            src="https://coca-public.oss-cn-beijing.aliyuncs.com/school_logo/wujiang-logo-1.png"
            alt=""
          />
        </div>
        <div className="h-[50px] flex items-center bg-[#979797] rounded-[25px] pl-[6px] pr-[16px] ml-auto">
          <img className="h-[40px] w-[40px] rounded-[50%]" src={user.photo ?? ''} alt="" />
          <div className="ml-[7px] text-[#c4c4c4]">
            <p>2021 大千班</p>
            <p>{user.nickname ?? user.name}</p>
          </div>
        </div>
        <div className="h-[40px] w-[40px] rounded-[50%] bg-[#c4c4c4] ml-[18px]"></div>
      </header>
      <section className="m-video bg-[#C4C4C4]">
        <div className="bg-[#4d4d4d]">
          <div className="bg-[#C4C4C4]"></div>
        </div>
      </section>
      <section className="s-video bg-[#C4C4C4]"></section>
      <section className="messages bg-[#C4C4C4]"></section>
      <section className="members flex">
        <div className="flex space-x-[10px] h-full overflow-hidden">
          {Array(5)
            .fill(0)
            .map((_, i) => {
              return <div key={i} className="w-[200px] h-full bg-[#979797] rounded-[10px]"></div>;
            })}
        </div>
        <div className="w-[64px] h-full bg-white ml-auto">
          <div>全部学生</div>
          <div>单独辅导</div>
        </div>
      </section>
    </div>
  );
};

export default ClassroomPage;
